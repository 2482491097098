import React from 'react';
import client from '../common/client'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createHashHistory } from 'history';

class StatisticTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data1 : [] ,
            data2 : [] ,
            loading : true
        }
    }

    componentDidMount() {
        const self = this;
        var path = '/api/chart/statistic_problem';
        if(this.props.omr_id){
          path = `/api/chart/statistic_problem?omr_id=${this.props.omr_id}`;
        }
        console.log('path', path);
        client.request_get(path).then(r => {
          self.setState({loading:false, ...r});
        });
    }

    render() {
        return (
            <div>
                {
                    !this.state.loading ? 

<Box>
  <Box m={1} width='100%' display="flex" direction="row" justify="space-between">
    <Button onClick={(e)=>{createHashHistory().push(`/result/${this.state.result_id}/show`)}}>
      <Typography variant="h9" gutterBottom>
        {this.state.book_name} - {this.state.omr_name}
      </Typography>
    </Button>
  </Box>

  <Box m={1} width='100%' display="flex" direction="row" justify="space-between">
    <Typography variant="h9" gutterBottom>
      회원명 : {this.state.member_name && this.state.member_name}
    </Typography>
  </Box>

  <Box 
  display="flex" 
  direction="row"
  justify="center"
  alignItems="flex-start">
  <Box m={1} width='50%'>
      <Typography variant="h9" gutterBottom>
        문항 별 통계
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>문항 번호</TableCell>
              <TableCell>정답률</TableCell>
              <TableCell>배점</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data1.map((row) => (
              <TableRow key={row.member_name}>
                <TableCell>{row.num}번</TableCell>
                <TableCell>{row.rate}%</TableCell>
                <TableCell>{row.point}점</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  </Box>
  <Box m={1} width='50%'>
      <Typography variant="h9" gutterBottom>
        문항 유형 별 통계
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>문제 유형</TableCell>
              <TableCell>정답률</TableCell>
              <TableCell>배점</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data2.map((row) => (
              <TableRow key={row.member_name}>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.rate}%</TableCell>
                <TableCell>{row.point}점</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  </Box>
</Box>
</Box>

                    : (
                    <Box
                        display="flex" 
                        width={'100%'} height={350} >
                        <Box m="auto">
                            <CircularProgress />
                        </Box>
                    </Box>
                    )
                }
            </div>
        )
    }
};

export default StatisticTable;