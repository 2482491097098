
import React, { Fragment, useState } from 'react';
import {
    ShowButton,
    SimpleShowLayout,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    ShowController,
    Show,
    ShowView,
    SelectInput,
    SimpleForm,
    TextInput,
    Filter,
    FormDataConsumer,
    ArrayInput,
    SimpleFormIterator,
    ImageField,
    ArrayField,
    BooleanField,
    BooleanInput,
    ImageInput,
    SelectArrayInput,
    NumberInput,
    NumberField,
    ReferenceInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    useNotify,
    Toolbar,
    SaveButton,
    ReferenceField,
    FileInput,
    FileField,
    SingleFieldList,
    FunctionField,
    DateField,
    DateInput,
    useRecordContext,
    DateTimeInput,
    TopToolbar,
    Button as RaButton,
    useRefresh,
    ReferenceArrayField,
    ReferenceArrayInput,
    ChipField,
    useGetList, useList, useListController, ListContextProvider,
    Labeled, ExportButton, downloadCSV, CreateButton,
    usePermissions,
} from 'react-admin';

import { Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField as MuiTextField,
    Button, Box, CircularProgress, Avatar, CardContent, CardActions, CardHeader, Card, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'
import client from '../common/client'
import moment from 'moment'
import ClickableImageField from '../component/ClickableImageField'

//Custom Import Start

//Custom Import End

const Firmware_devTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>Firmware(Dev)</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const Firmware_devFilter = props => (
    <Filter {...props}>
        <TextInput resettable label="id" source="id" alwaysOn/>
        <SelectInput resettable label="Product" source="product" choices={[{"id":"DC-H3-FG","name":"DC-H3-FG"},{"id":"DC-M3-FG","name":"DC-M3-FG"}]} alwaysOn/>

            <TextInput resettable label="Version" source="version" alwaysOn/>
                

        {
        //Custom Filter Start
        
//Custom Filter End
        }
    </Filter>
);

export const Firmware_devList = props => {
    const navigate = useNavigate()
    const refresh = useRefresh();

    //Custom List Code Start

    //Custom List Code End
    return (
        <List title="Firmware(Dev)" {...props} filters={<Firmware_devFilter/>} mutationMode='optimistic'
            exporter={false}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={30}
            //Custom List Action Start
        
//Custom List Action End
        >
            {
                //Custom List Body Start
            
//Custom List Body End
            }
            <Datagrid rowClick="show" bulkActionButtons={true}>
                <TextField label="ID" source="id" />
                <FunctionField label="Product" render={record => {
                    let list = [{"id":"DC-H3-FG","name":"DC-H3-FG"},{"id":"DC-M3-FG","name":"DC-M3-FG"}]
                    if(record.product) {
                        let ff = list.filter(f=>f.id==record.product)
                        return ff.length > 0 ? ff[0].name : record.product
                    } else {
                        return ''
                    }
                }} />
<TextField label="Version" source="version" false/>
<TextField label="업데이트 내역" source="update_info_text" component="pre"/>
                //Custom List Start
        
//Custom List End
                <EditButton label='수정'/>
            </Datagrid>
        </List>
    )
};

const required = (message = 'Required') =>
    value => value ? undefined : message;
const maxLength = (max, message = (max + '자 이하로 입력해주세요')) =>
    value => value && value.length > max ? message : undefined;
const validateName = [required(), maxLength(15)];
const validateRequire = [required()];


export const Firmware_devCreate = props => {
    const { permissions } = usePermissions();
    //Custom Create Code Start

    //Custom Create Code End
    return (
        <Create title={<Firmware_devTitle />} {...props} mutationMode='optimistic' redirect="list" 
            //Custom Create Property Start
        
//Custom Create Property End
        >
            <SimpleForm toolbar={<EditToolbar />} 
                //Custom Create SimpleForm Property Start
            
//Custom Create SimpleForm Property End
            >
                <SelectInput resettable label="Product" source="product" defaultValue="null" choices={[{"id":"DC-H3-FG","name":"DC-H3-FG"},{"id":"DC-M3-FG","name":"DC-M3-FG"}]} validate={validateRequire}/>
<TextInput  resettable label="Version" source="version" validate={validateRequire}/>
<TextInput multiline fullWidth resettable label="업데이트 내역" source="update_info_text" validate={validateRequire}/>
<FileInput source="file" placeholder={<p>File</p>} validate={validateRequire}>
    <FileField source="src" title="title"/>
</FileInput>
                {/* Custom Create Start */}
            
{/* Custom Create End */}
            </SimpleForm>
        </Create>
    )
}

export const Firmware_devEdit = props => (
    <Edit title={<Firmware_devTitle />} {...props} mutationMode='optimistic' redirect="edit">
        <SimpleForm toolbar={<EditToolbar />} >
            <SelectInput resettable label="Product" source="product" defaultValue="null" choices={[{"id":"DC-H3-FG","name":"DC-H3-FG"},{"id":"DC-M3-FG","name":"DC-M3-FG"}]} validate={validateRequire}/>
<TextInput  resettable label="Version" source="version" validate={validateRequire}/>
<TextInput multiline fullWidth resettable label="업데이트 내역" source="update_info_text" validate={validateRequire}/>
<FileInput source="file" placeholder={<p>File</p>} validate={validateRequire}>
    <FileField source="src" title="title"/>
</FileInput>
            {/* Custom Edit Start */}
        
{/* Custom Edit End */}
        </SimpleForm>
    </Edit>
);

export const Firmware_devShow = ({ ...props }) => {
    //Custom Show Init Start
        
//Custom Show Init End
    return (
        <Show title={<Firmware_devTitle />} {...props} >
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
            <FunctionField label="Product" render={record => {
                    let list = [{"id":"DC-H3-FG","name":"DC-H3-FG"},{"id":"DC-M3-FG","name":"DC-M3-FG"}]
                    if(record.product) {
                        let ff = list.filter(f=>f.id==record.product)
                        return ff.length > 0 ? ff[0].name : record.product
                    } else {
                        return ''
                    }
                }} />
<TextField label="Version" source="version" false/>
<TextField label="업데이트 내역" source="update_info_text" component="pre"/>

        <FunctionField label="File" render={record => 
            <a href={record.file && record.file.url} target="_blank">{record.file && record.file.title}</a>
        } />
        

            //Custom Show Start
        
//Custom Show End

            </SimpleShowLayout>
        </Show>
    )
};






//Custom Outside Start

//Custom Outside End
 