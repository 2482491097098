
export const TypeList =
[
    { id: 'SM', name: '대학수학능력시험' },
    { id: 'HG', name: '모의고사' },
    { id: 'SG', name: '수능 교재' },
    { id: 'TR', name: '토익 - RC' },
    { id: 'TL', name: '토익 - LC' },
];

export const AgeList = [
    { id: 'J1', name: '중1' },
    { id: 'J2', name: '중2' },
    { id: 'J3', name: '중3' },
    { id: 'K1', name: '고1' },
    { id: 'K2', name: '고2' },
    { id: 'K3', name: '고3' },
    { id: 'D1', name: '대학' },
];

export const YearList = [
    { id: '2020', name: '2020' },
    { id: '2019', name: '2019' },
    { id: '2018', name: '2018' },
    { id: '2017', name: '2017' },
    { id: '2016', name: '2016' },
    { id: '2015', name: '2015' },
    { id: '2014', name: '2014' },
    { id: '2013', name: '2013' },
    { id: '2012', name: '2012' },
    { id: '2011', name: '2011' },
];

export const MonthList = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
    { id: '6', name: '6' },
    { id: '7', name: '7' },
    { id: '8', name: '8' },
    { id: '9', name: '9' },
    { id: '10', name: '10' },
    { id: '11', name: '11' },
    { id: '12', name: '12' },
];

export const SubjectList =
[
    { id: '국어', name: '국어' },
    { id: '수학', name: '수학' },
    { id: '수학(가)', name: '수학(가)' },
    { id: '수학(나)', name: '수학(나)' },
    { id: '영어', name: '영어' },
    { id: '한국사', name: '한국사' },
    { id: '물리1', name: '물리1' },
    { id: '물리2', name: '물리2' },
    { id: '화학1', name: '화학1' },
    { id: '화학2', name: '화학2' },
    { id: '생명과학1', name: '생명과학1' },
    { id: '생명과학2', name: '생명과학2' },
    { id: '지구과학1', name: '지구과학1' },
    { id: '지구과학2', name: '지구과학2' },
    { id: '통합과학', name: '통합과학' },
    { id: '한국지리', name: '한국지리' },
    { id: '세계지리', name: '세계지리' },
    { id: '세계사', name: '세계사' },
    { id: '동아시아사', name: '동아시아사' },
    { id: '사회·문화', name: '사회·문화' },
    { id: '법과정치', name: '법과정치' },
    { id: '경제', name: '경제' },
    { id: '생활과윤리', name: '생활과윤리' },
    { id: '윤리와 사상', name: '윤리와 사상' },
    { id: '통합사회', name: '통합사회' },
    { id: '고2수학', name: '고2수학' },
    { id: '고1수학', name: '고1수학' },
];

export const SubjectListForSG =
[
    { id: '국어', name: '국어' },
    { id: '수학', name: '수학' },
    { id: '수학(가)', name: '수학(가)' },
    { id: '수학(나)', name: '수학(나)' },
    { id: '고2수학', name: '고2수학' },
    { id: '고1수학', name: '고1수학' },
    { id: '영어', name: '영어' },
    { id: '한국사', name: '한국사' },
    { id: '물리1', name: '물리1' },
    { id: '물리2', name: '물리2' },
    { id: '화학1', name: '화학1' },
    { id: '화학2', name: '화학2' },
    { id: '생명과학1', name: '생명과학1' },
    { id: '생명과학2', name: '생명과학2' },
    { id: '지구과학1', name: '지구과학1' },
    { id: '지구과학2', name: '지구과학2' },
    { id: '통합과학', name: '통합과학' },
    { id: '한국지리', name: '한국지리' },
    { id: '세계지리', name: '세계지리' },
    { id: '세계사', name: '세계사' },
    { id: '동아시아사', name: '동아시아사' },
    { id: '사회·문화', name: '사회·문화' },
    { id: '법과정치', name: '법과정치' },
    { id: '경제', name: '경제' },
    { id: '생활과윤리', name: '생활과윤리' },
    { id: '윤리와 사상', name: '윤리와 사상' },
    { id: '통합사회', name: '통합사회' },
];


export const RankList =
[
    { id: '1', name: '1등급' },
    { id: '2', name: '2등급' },
    { id: '3', name: '3등급' },
    { id: '4', name: '4등급' },
    { id: '5', name: '5등급' },
    { id: '6', name: '6등급' },
    { id: '7', name: '7등급' },
    { id: '8', name: '8등급' },
    { id: '9', name: '9등급' },
    { id: '10', name: '10등급' },
    { id: '11', name: '11등급' },
    { id: '12', name: '12등급' },
    { id: '13', name: '13등급' },
    { id: '14', name: '14등급' },
    { id: '15', name: '15등급' },
    { id: '16', name: '16등급' },
    { id: '17', name: '17등급' },
    { id: '18', name: '18등급' },
    { id: '19', name: '19등급' },
    { id: '20', name: '20등급' },
    { id: '21', name: '21등급' },
    { id: '22', name: '22등급' },
    { id: '23', name: '23등급' },
    { id: '24', name: '24등급' },
    { id: '25', name: '25등급' },
    { id: '26', name: '26등급' },
    { id: '27', name: '27등급' },
];


export const AnswerTypeList =
[
    { id: '화법', name: '화법' },
    { id: '작문', name: '작문' },
    { id: '문법', name: '문법' },
    { id: '현대시', name: '현대시' },
    { id: '고전소설', name: '고전소설' },
    { id: '과학', name: '과학' },
    { id: '현대소설', name: '현대소설' },
    { id: '도서 복합', name: '도서 복합' },
    { id: '문학 복합', name: '문학 복합' },
    { id: '인문', name: '인문' },
    { id: '한국사', name: '한국사' },
];

export const AnswerTypeList2 =
[
    { id: '화법2', name: '화법2' },
    { id: '작문2', name: '작문2' },
];

export const AnswerTypeList3 =
[
    { id: '화법3', name: '화법3' },
    { id: '작문3', name: '작문3' },
];

export const getLoginToken = () => {
    var token = localStorage.getItem('token');
    if(!token){
        token = getCookie('token');
        if(token){
            localStorage.setItem('token', token);
        }
    }

    return token;
}


export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}