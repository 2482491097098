import React from 'react';
import client from '../common/client'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

class TotalMileageSpend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {} ,
            loading : true
        }
    }

    componentDidMount() {
        const self = this;
        client.request_get('/api/chart/withdraw').then(r => {
            self.setState({loading:false, data:r});
        });
    }

    render() {
        return (
            <div>
                {
                    !this.state.loading ? 

    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>회원명</TableCell>
            <TableCell align="right">은행</TableCell>
            <TableCell align="right">계좌번호</TableCell>
            <TableCell align="right">예금주</TableCell>
            <TableCell align="right">금액</TableCell>
            <TableCell align="right">일시</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.data.map((row) => (
            <TableRow key={row.member_name}>
              <TableCell scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.bank}</TableCell>
              <TableCell align="right">{row.acount}</TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{parseInt(row.amount).toLocaleString()}</TableCell>
              <TableCell align="right">{row.date_ago}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


                    : (
                    <Box
                        display="flex" 
                        width={'100%'} height={150} >
                        <Box m="auto">
                            <CircularProgress />
                        </Box>
                    </Box>
                    )
                }
            </div>
        )
    }
};

export default TotalMileageSpend;