import React from 'react';
import logo from './logo.svg';
import {api_host} from './config/config.js'
import PostIcon from '@mui/icons-material/Book';
import UserIcon from '@mui/icons-material/Group';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import GradeIcon from '@mui/icons-material/Grade';
import MajorIcon from '@mui/icons-material/Computer';
import SoundIcon from '@mui/icons-material/Hearing';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RankIcon from '@mui/icons-material/FormatListNumbered';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import SettingIcon from '@mui/icons-material/Settings';
import AllOutIcon from '@mui/icons-material/AllOut';

import { fetchUtils, Admin, Resource, ListGuesser } from 'react-admin';

import jsonServerProvider from 'ra-data-json-server';
import addUploadFeature from './dataprovider/addUploadFeature.js';

import './App.css';
import Dashboard from './Dashboard';
import authProvider from './authProvider';

import { UserList, UserShow} from './users';
import { AdminList, AdminEdit, AdminCreate, AdminShow} from './template/admins';

import NoticeIcon from '@mui/icons-material/Computer';
import { NoticeList, NoticeEdit, NoticeCreate, NoticeShow} from './template/Notice';

//Devil Auto Gen Import Start
import FirmwareIcon from '@mui/icons-material/Memory';
import { FirmwareList, FirmwareCreate, FirmwareEdit, FirmwareShow } from './gen/Firmware';
import Firmware_devIcon from '@mui/icons-material/MemoryTwoTone';
import { Firmware_devList, Firmware_devCreate, Firmware_devEdit, Firmware_devShow } from './gen/Firmware_dev';
import Track_eventIcon from '@mui/icons-material/MonitorHeart';
import { Track_eventList, Track_eventCreate, Track_eventEdit, Track_eventShow } from './gen/Track_event';

//Devil Auto Gen Import End

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    //console.log('token - ' + token);
    options.headers.set('x-access-token', token);

    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(api_host, httpClient);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
    <Admin
        dataProvider={uploadCapableDataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
    >
        <Resource name="user" options={{ label: '회원 관리' }} icon={UserIcon} list={UserList} show={UserShow}/>

{/* Devil Auto Gen Resource Start */}
<Resource name="firmware" options={{ label: 'Firmware(Prod)' }} 
    icon={FirmwareIcon}
    list={FirmwareList} create={FirmwareCreate} edit={FirmwareEdit} show={FirmwareShow}
/>
<Resource name="firmware_dev" options={{ label: 'Firmware(Dev)' }} 
    icon={Firmware_devIcon}
    list={Firmware_devList} create={Firmware_devCreate} edit={Firmware_devEdit} show={Firmware_devShow}
/>
<Resource name="track_event" options={{ label: 'Track Event' }} 
    icon={Track_eventIcon}
    list={Track_eventList}   show={Track_eventShow}
/>

{/* Devil Auto Gen Resource End */}

        <Resource name="admin" options={{ label: '관리자 계정' }} icon={SoundIcon}
            list={AdminList}
            create={AdminCreate}
            edit={AdminEdit}
            show={AdminShow} />
    </Admin>
  );

export default App;

