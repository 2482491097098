import React from 'react';

import {
    Show,
    ShowButton,
    SimpleShowLayout,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    ArrayInput,
    SimpleFormIterator,
    ArrayField,
    ChipField,
    SingleFieldList,
    BooleanInput,
    FileInput,
    FileField,
    BooleanField,
    DateField,
} from 'react-admin';

const AdminFilter = props => (
    <Filter {...props}>
        <TextInput resettable label="이름" source="name" alwaysOn />
    </Filter>
);

export const AdminList = props => (
    <List title="관리자" {...props} 
        exporter={false}
        filters={<AdminFilter />} sort={{ field: 'id', order: 'DESC' }} undoable={false}>
        <Datagrid>

            <TextField label="관리자 아이디" source="email"/>
            <TextField label="이름" source="name"/>
            <BooleanField label="슈퍼 관리자" source="super"/>
            <DateField label="최근 접속일" source="login_date"/>
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const AdminTitle = ({ record }) => {
    return <span>관리자 {record ? `"${record.name}"` : ''}</span>;
};

const required = (message = 'Required') =>
    value => value ? undefined : message;
const maxLength = (max, message = (max +'자 이하로 입력해주세요')) =>
    value => value && value.length > max ? message : undefined;
const validateName = [required(), maxLength(15)];
const validateRequire = [required()];

export const AdminCreate = props => (
    <Create {...props} undoable={false}>
        <SimpleForm>
            <TextInput resettable label="관리자 이름" source="name" validate={validateName}/>
            <TextInput resettable label="관리자 아이디" source="email" validate={validateName}/>
            <TextInput resettable label="관리자 패스워드" source="password" validate={validateName}/>
            <BooleanInput label="슈퍼 관리자 여부" source="super" defaultValue={false}/>
        </SimpleForm>
    </Create>
);

export const AdminEdit = props => (
    <Edit title={<AdminTitle />} {...props} undoable={false}>
        <SimpleForm>
            <TextInput resettable label="관리자 이름" source="name" validate={validateName}/>
            <TextInput resettable label="관리자 기존 패스워드" source="old_password"/>
            <TextInput resettable label="관리자 신규 패스워드" source="password"/>
            <BooleanInput label="슈퍼 관리자 여부" source="super" defaultValue={false}/>
        </SimpleForm>
    </Edit>
);

export const AdminShow = props => (
    <Show title={<AdminTitle />} {...props}>
        <SimpleShowLayout>
            <TextField label="관리자 아이디" source="email"/>
            <TextField label="이메일" source="name"/>
            <BooleanField label="슈퍼 관리자" source="super"/>
            <DateField label="최근 접속일" source="login_date"/>
        </SimpleShowLayout>
    </Show>
);