import React from 'react';
import client from '../common/client'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';


class TotalMileage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {} ,
            loading : true
        }
    }

    componentDidMount() {
        const self = this;
        client.request_get(this.props.path).then(r => {
            self.setState({loading:false, data:r.r});
        });
    }

    render() {
        return (
            <div>
                {
                    !this.state.loading ? 
                    <Typography variant="h2" gutterBottom>
                        {this.state.data.toLocaleString()}
                    </Typography>
                    : (
                    <Box
                        display="flex" 
                        width={'100%'} height={150} >
                        <Box m="auto">
                            <CircularProgress />
                        </Box>
                    </Box>
                    )
                }
            </div>
        )
    }
};

export default TotalMileage;