import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import PieChart from './chart/PieChart';
import ColumnChart from './chart/ColumnChart';
import TotalMileage from './chart/TotalMileage';
import TotalMileageSpend from './chart/TotalMileageSpend';
import StatisticTable from './chart/StatisticTable';

export default () => {
    const [path1, setPath1] = useState('/api/chart/member');

    return (
    <Grid
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
        >
            <Grid item xs={6} >
                <Card>
                    <CardHeader titleTypographyProps={{variant:'h9' }} title="< 회원 변동 (일/주/월) 선 그래프 >" 
                        action={
                            <Box>
                                <Button onClick={() => {setPath1('/api/chart/member')}} >일</Button>
                                <Button onClick={() => {setPath1('/api/chart/member?m=week')}}>주</Button>
                                <Button onClick={() => {setPath1('/api/chart/member?m=month')}}>월</Button>
                            </Box>
                      }
                    />
                    <CardContent>
                        <ColumnChart path={path1}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}  >
                <Card>
                    <CardHeader titleTypographyProps={{variant:'h9' }} title="< 회원 가입 유형 통계 >" />
                    <CardContent>
                        <ColumnChart path='/api/chart/member_type'/>
                    </CardContent>
                </Card>
            </Grid>
    </Grid>
    
)};
