import {getLoginToken} from './common.js'
import {api_host} from '../config/config.js'

const request_get = async (path) => {
    var token = getLoginToken();
    var header = { 'Content-Type': 'application/json'};
    if(token)
        header['x-access-token'] = token;

    const request = new Request(`${api_host}${path}`, {
        method: 'GET',
        headers: new Headers(header),
    })
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                ;//throw new Error(response.statusText);
            }
            return response.json();
        });
}

const request = async (path, method, data) => {
    var token = getLoginToken();
    var header = { 'Content-Type': 'application/json'};
    if(token)
        header['x-access-token'] = token;

    const request = new Request(`${api_host}${path}`, {
        method: method,
        headers: new Headers(header),
        body: data?JSON.stringify(data):null,
    })
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                ;//throw new Error(response.statusText);
            }
            return response.json();
        });
}

const request_post = async (path, param) => {
    var token = getLoginToken();
    var header = { 'Content-Type': 'application/json'};
    if(token)
        header['x-access-token'] = token;
    const request = new Request(`${api_host}${path}`, {
        method: 'POST',
        headers: new Headers(header),
        body: JSON.stringify(param),
    })
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                ;//throw new Error(response.statusText);
            }
            return response.json();
        });

}

export default {
    request_get,
    request,
    request_post,
};