
import React, { Fragment, useState } from 'react';
import {
    ShowButton,
    SimpleShowLayout,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    ShowController,
    Show,
    ShowView,
    SelectInput,
    SimpleForm,
    TextInput,
    Filter,
    FormDataConsumer,
    ArrayInput,
    SimpleFormIterator,
    ImageField,
    ArrayField,
    BooleanField,
    BooleanInput,
    ImageInput,
    SelectArrayInput,
    NumberInput,
    NumberField,
    ReferenceInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    useNotify,
    Toolbar,
    SaveButton,
    ReferenceField,
    FileInput,
    FileField,
    SingleFieldList,
    FunctionField,
    DateField,
    DateInput,
    useRecordContext,
    DateTimeInput,
    TopToolbar,
    Button as RaButton,
    useRefresh,
    ReferenceArrayField,
    ReferenceArrayInput,
    ChipField,
    useGetList, useList, useListController, ListContextProvider,
    Labeled, ExportButton, downloadCSV, CreateButton,
    usePermissions,
} from 'react-admin';

import { Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField as MuiTextField,
    Button, Box, CircularProgress, Avatar, CardContent, CardActions, CardHeader, Card, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'
import client from '../common/client'
import moment from 'moment'
import ClickableImageField from '../component/ClickableImageField'

//Custom Import Start

//Custom Import End

const Track_eventTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>Track Event</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const Track_eventFilter = props => (
    <Filter {...props}>
        <TextInput resettable label="_id" source="_id" alwaysOn/>
        
            <TextInput resettable label="Trip ID" source="trip_id" alwaysOn/>
                

            <ReferenceInput source="member_no" reference="user" alwaysOn>
                <AutocompleteInput label="사용자" optionText="name" />
            </ReferenceInput>
                

            <TextInput resettable label="기기" source="device_serial" alwaysOn/>
                

        {
        //Custom Filter Start
        
//Custom Filter End
        }
    </Filter>
);

export const Track_eventList = props => {
    const navigate = useNavigate()
    const refresh = useRefresh();

    //Custom List Code Start

    //Custom List Code End
    return (
        <List title="Track Event" {...props} filters={<Track_eventFilter/>} mutationMode='optimistic'
            exporter={false}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={30}
            //Custom List Action Start
        
//Custom List Action End
        >
            {
                //Custom List Body Start
            
//Custom List Body End
            }
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="ID" source="id" />
                <TextField label="Trip ID" source="trip_id" false/>
<ReferenceField link="show" label="사용자" source="member_no" reference="user">
            <TextField source="name" />
        </ReferenceField>
<TextField label="기기" source="device_serial" false/>
<DateField label="발생일시" source="reg_date" showTime={true}/>
<TextField label="Lat" source="event_data. latitude" false/>
<TextField label="Lng" source="event_data. longitude" false/>
                //Custom List Start
        
//Custom List End
                
            </Datagrid>
        </List>
    )
};

const required = (message = 'Required') =>
    value => value ? undefined : message;
const maxLength = (max, message = (max + '자 이하로 입력해주세요')) =>
    value => value && value.length > max ? message : undefined;
const validateName = [required(), maxLength(15)];
const validateRequire = [required()];


export const Track_eventCreate = props => {
    const { permissions } = usePermissions();
    //Custom Create Code Start

    //Custom Create Code End
    return (
        <Create title={<Track_eventTitle />} {...props} mutationMode='optimistic' redirect="list" 
            //Custom Create Property Start
        
//Custom Create Property End
        >
            <SimpleForm toolbar={<EditToolbar />} 
                //Custom Create SimpleForm Property Start
            
//Custom Create SimpleForm Property End
            >
                
                {/* Custom Create Start */}
            
{/* Custom Create End */}
            </SimpleForm>
        </Create>
    )
}

export const Track_eventEdit = props => (
    <Edit title={<Track_eventTitle />} {...props} mutationMode='optimistic' redirect="edit">
        <SimpleForm toolbar={<EditToolbar />} >
            
            {/* Custom Edit Start */}
        
{/* Custom Edit End */}
        </SimpleForm>
    </Edit>
);

export const Track_eventShow = ({ ...props }) => {
    //Custom Show Init Start
        
//Custom Show Init End
    return (
        <Show title={<Track_eventTitle />} {...props} >
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
            <TextField label="Trip ID" source="trip_id" false/>
<ReferenceField link="show" label="사용자" source="member_no" reference="user">
            <TextField source="name" />
        </ReferenceField>
<TextField label="기기" source="device_serial" false/>
<DateField label="발생일시" source="reg_date" showTime={true}/>
<TextField label="Lat" source="event_data. latitude" false/>
<TextField label="Lng" source="event_data. longitude" false/>

            //Custom Show Start
        
//Custom Show End

            </SimpleShowLayout>
        </Show>
    )
};








//Custom Outside Start

//Custom Outside End
 