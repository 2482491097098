import React from "react";
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  DateField,
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  EmailField,
  ReferenceField,
  ArrayField,
  SelectInput,
  EditButton,
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  FunctionField,
  BooleanInput,
  BooleanField,
} from "react-admin";

// 상단 검색부분
const UserFilter = props => (
  <Filter {...props}>
    <TextInput resettable label="회원번호" source="member_no" alwaysOn />
    <TextInput resettable label="Email" source="email" alwaysOn />
    <TextInput resettable label="이름" source="name" alwaysOn />
  </Filter>
);

// 유저목록 리스트
export const UserList = props => (
  <List
    title="회원 관리"
    {...props}
    exporter={false}
    filters={<UserFilter />}
    bulkActionButtons={false}
    sort={{ field: "member_no", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="회원번호" source="member_no" />
      <TextField label="Email" source="email" />
      <TextField label="OS" source="push.os" />
      <TextField label="이름" source="name" />
      <DateField label="가입일" source="join_date" />
      <BooleanField label="관리자 승인" source="approve" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

// ----------여기서부터 회원 상세보기 페이지-------------
const UserTitle = ({ record }) => {
  return <span>회원 정보</span>;
};
const empty = ({ record }) => {
  return "";
};



export const UserShow = props => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="회원번호" source="member_no" />
      <FunctionField label="전화번호" render={record => (record.phone?`${record.phone.substring(0, 3)}-${record.phone.substring(3, 7)}-${record.phone.substring(7, 11)}`:`전화번호 없음`)} />
      <TextField label="이름" source="name" />
      <DateField label="가입일" source="join_date" />
      <BooleanField label="관리자 승인" source="approve" />
    </SimpleShowLayout>
  </Show>
);


const UserEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props} undoable={false}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <BooleanInput label="관리자 승인" source="approve" />
    </SimpleForm>
  </Edit>
);