import client from '../common/client'

// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
 const convertFileToBase64 = file => new Promise((resolve, reject) => {
    
    if(file && file.rawFile){
        //console.log('convertFileToBase64 - encoding');
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    } else  {
        //console.log('convertFileToBase64 - pass');
        resolve(null);
    }
});

/**
 * 신규 파일 서버 업로드시 다음과 같이 송신하고 서버는 그대로 저장
 * 물론 s3 upload과정은 별도로 한다
 * [이름] = {src:'media/.../...', title:'filename'}
 * 
 * 기존 파일 업로드시 도 위와 같이 송신하고 서버는 그대로 저장
 * 
 * view api에서 [이름].src에 http://를 prefix를 붙여서 url에 저장한다
 * prefix는 secure여부에 따라 aws_bucket_image 혹은 aws_bucket_private 를 사용한다
 * 
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
const uploadFile = (name, value) => new Promise((resolve, reject) => {
    if(value && value.rawFile && value.rawFile){
        const reader = new FileReader();
        reader.readAsDataURL(value.rawFile);
        reader.onload = () => {
            let byte = reader.result;
            //console.log('byte', byte)
            let ext = value.title.substring(value.title.lastIndexOf('.')+1, value.title.length);
            //console.log('ext', ext)
            client.request_get('/api/media/url/put/'+ ext).then(res=>{
                fetch(res.upload_url, { method: "PUT", headers:{'Content-Type':res.contentType}, body:value.rawFile })
                .then(res2=>{
                    if(res2.ok) {
                        console.log('uploadFile', 'upload_success', res2);
                        value.src = res.key
                        delete value.rawFile
                        resolve(value)
                    } else {
                        reject(res2.statusText)
                    }
                });
            })
        };
        reader.onerror = reject;
    } else  {
        //console.log('convertFileToBase64 - pass');
        resolve(null);
    }
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = (requestHandler) => async (type, resource, params) => {
    // console.log('-----------------requestHandler-----------------');
    // console.log(requestHandler);
    // console.log(type);
    // console.log(resource);
    // console.log(params);

    /**
     * CREATE시
     "problem": [
      {
        "answer": "1",
        "pdf": {
          "src": "blob:http://localhost:3000/e881b36a-72f8-47b6-8f7c-5da04686107e",
          "title": "question.pdf",
          "rawFile": {
            "preview": "blob:http://localhost:3000/e881b36a-72f8-47b6-8f7c-5da04686107e"
          }
        }
      }
    ]
     * UPDATE시
     "problem": [
      {
        "answer": "1",
        "pdf": {
          "src": "http://image.symplai.co.kr/pdf/1563284656157/_0.pdf",
          "title": "_1.pdf"
        }
      }
    ],
     */
    if ( (type == 'CREATE' || type === 'UPDATE')) {
        var pp = []
        Object.keys(params.data).map(m=>{
            let value = params.data[m]
            if(value && value.rawFile) {
                console.log('point1')
                pp.push(uploadFile(m, value))
            }
            else if(value && Array.isArray(value) && value.length > 0) {
                value.map(v=>{
                    Object.keys(v).map(mm=>{
                        let vv = v[mm]
                        if(vv && vv.rawFile) {
                            console.log('point2')
                            pp.push(uploadFile(mm, vv))
                        } else if(vv && Array.isArray(vv) && vv.length > 0) {
                            vv.map(vvv=>{
                                Object.keys(vvv).map(mmm=>{
                                    let vvvv = vvv[mmm]
                                    if(vvvv && vvvv.rawFile) {
                                        console.log('point3')
                                        pp.push(uploadFile(mmm, vvvv))
                                    }
                                })
                            })
                        }
                    })
                })
            }
        })

        // console.log('pp.length', pp.length)
        return Promise.all(pp).then(results => {
            var r = {
                ...params
            };

            // console.log('results.length', results.length);
            for(let i=0;i<results.length;i++) {
                if(results[i]) {
                    
                }
            }

            return requestHandler[typeToType(type)](resource, r);
        });
    } else{
        return requestHandler[typeToType(type)](resource, params);
    } 
        
};

function typeToType(type) {
    if(type == 'GET_LIST')
        return 'getList';
    if(type == 'GET_ONE')
        return 'getOne';
    if(type == 'GET_MANY')
        return 'getMany';
    if(type == 'GET_MANY_REFERENCE')
        return 'getManyReference';
    if(type == 'UPDATE')
        return 'update';
    if(type == 'UPDATE_MANY')
        return 'updateMany';
    if(type == 'CREATE')
        return 'create';
    if(type == 'DELETE')
        return 'delete';
    if(type == 'DELETE_MANY')
        return 'deleteMany';
    return type;
}

export default addUploadFeature;