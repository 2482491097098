import React from 'react';
import client from '../common/client'

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);



class ColumnChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data : {} ,
            loading : true
        }
    }

    componentDidMount() {
        const self = this;
        client.request_get(this.props.path).then(r => {
            self.setState({loading:false, data:r});
        });
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.path != this.props.path){
            const self = this;
            client.request_get(nextProps.path).then(r => {
                self.setState({loading:false, data:r});
            });
        }
        return true;
    }

    render() {
        return (
            <div>
                {
                    !this.state.loading ? 
                    <ReactFC {...{
                        type: 'column2d',
                        width: '100%',
                        height: '350',
                        dataFormat: 'json',
                        dataSource: this.state.data
                    }}/> 
                    : (
                    <Box
                        display="flex" 
                        width={'100%'} height={350} >
                        <Box m="auto">
                            <CircularProgress />
                        </Box>
                    </Box>
                    )
                }
            </div>
        )
    }
};

export default ColumnChart;