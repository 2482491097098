
import React, { Fragment, useState } from 'react';
import {
    ShowButton,
    SimpleShowLayout,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    ShowController,
    Show,
    ShowView,
    SelectInput,
    SimpleForm,
    TextInput,
    Filter,
    FormDataConsumer,
    ArrayInput,
    SimpleFormIterator,
    ImageField,
    ArrayField,
    BooleanField,
    BooleanInput,
    ImageInput,
    SelectArrayInput,
    NumberInput,
    NumberField,
    ReferenceInput,
    AutocompleteInput,
    useNotify,
    Toolbar,
    SaveButton,
    ReferenceField,
    FileInput,
    FileField,
    SingleFieldList,
    FunctionField,
    DateField,
    DateInput,
    useRecordContext,
    DateTimeInput,
} from 'react-admin';

import { Button, Box, CircularProgress, CardContent, CardActions, CardHeader, Card, Grid } from '@mui/material';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom'

const NoticeTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>공지사항 관리</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const NoticeFilter = props => (
    <Filter {...props}>
        {
            //Custom Filter Start
            <SelectInput resettable label="타입" source="type" alwaysOn
                choices={[{ name: '공지' }, { name: '사용방법' }, { name: '결제관련' }, { name: '기타문의' }]} optionText="name" optionValue="name"
            />
            //Custom Filter End
        }
    </Filter>
);

export const NoticeList = props => {
    return (
        <List title="공지사항 관리" {...props} filters={<NoticeFilter />}
            exporter={false}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={100}
        >
            <Datagrid rowClick="show" bulkActionButtons={true}>
                <TextField label="Id" source="id" sortable={false} />
                <TextField label="종류" source="type" sortable={false} />
                <TextField label="제목" source="title" sortable={false} />
                <DateField label="등록일" source="reg_date" sortable={false} />
                <EditButton label="수정" />
            </Datagrid>
        </List>
    )
};

const required = (message = 'Required') =>
    value => value ? undefined : message;
const maxLength = (max, message = (max + '자 이하로 입력해주세요')) =>
    value => value && value.length > max ? message : undefined;
const validateName = [required(), maxLength(15)];
const validateRequire = [required()];


export const NoticeCreate = props => (
    <Create title={<NoticeTitle />} {...props} mutationMode='pessimistic' redirect="list">
        <SimpleForm toolbar={<EditToolbar />} >

            <SelectInput resettable label="타입" source="type" alwaysOn
                choices={[{ name: '공지' }, { name: '사용방법' }, { name: '결제관련' }, { name: '기타문의' }]} optionText="name" optionValue="name"
            />
            <TextInput resettable label="제목" source="title" validate={validateRequire} />
            <TextInput label="내용" source="content" validate={validateRequire}
                multiline rowsMax="20" fullWidth={true}
            />

            <ArrayInput label="상세이미지" source={'img_list'} >
                <SimpleFormIterator>

                    <ImageInput source="img" label="상세이미지" accept="image/*" placeholder={<p>상세이미지</p>} validate={validateRequire}>
                        <ImageField source="src" title="title" sx={{ '& img': { maxHeight: '20rem' } }} />
                    </ImageInput>

                </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    </Create>
);

export const NoticeEdit = props => (
    <Edit title={<NoticeTitle />} {...props} mutationMode='pessimistic' redirect="edit">
        <SimpleForm toolbar={<EditToolbar />} >

            <SelectInput resettable label="타입" source="type" alwaysOn
                choices={[{ name: '공지' }, { name: '사용방법' }, { name: '결제관련' }, { name: '기타문의' }]} optionText="name" optionValue="name"
            />
            <TextInput resettable label="제목" source="title" validate={validateRequire} />
            <TextInput label="내용" source="content" validate={validateRequire}
                multiline type="text" rowsMax="20" fullWidth={true}
            />

            <ArrayInput label="상세이미지" source={'img_list'} >
                <SimpleFormIterator>

                    <ImageInput source="img" label="상세이미지" accept="image/*" placeholder={<p>상세이미지</p>} validate={validateRequire}>
                        <ImageField source="src" title="title" sx={{ '& img': { maxHeight: '20rem' } }} />
                    </ImageInput>

                </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    </Edit>
);

export const NoticeShow = ({ ...props }) => {
    return (
        <Show title={<NoticeTitle />} {...props} >
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
                <TextField label="종류" source="type" />
                <TextField label="제목" source="title" />
                <TextField label="내용" source="content" component="pre" />
                <DateField label="등록일" source="reg_date" showTime={true} />

                <ArrayField label="상세이미지" source="img_list" >
                    <Datagrid bulkActionButtons={false}>
                        <ImageField sx={{
                            '& img': {
                                width: '20rem',
                                height: '20rem',
                                objectFit: 'contain',
                                background: '#ededed',
                            }
                        }} label="상세이미지" source="img.url" />
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>
    )
};
